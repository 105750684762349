import { useCallback, useEffect, useState } from "react";
import shape2 from "./../../../assets/img/shape2.png";
import shape3 from "./../../../assets/img/shape3.png";
import banneraboutus from "./../../../assets/img/banneraboutus.jpg";
import { Link, useLocation } from "react-router-dom";
import { routePortals } from "../../../routes/PortalRoute";
import {
  ContactInfoAPI,
  BannerSettingAPI,
  CommonSettingAPI,
} from "../../../apis";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Keyboard, Mousewheel, Navigation, Pagination } from "swiper/modules";
import bannercontactusjpg from "./../../../assets/img/bannercontactusjpg.jpg";

const Header = () => {
  const isUsingCloudStorage = process.env.REACT_APP_CLOUD_STORAGE === "true";
  const backendProxyURL = process.env.REACT_APP_BACKEND_PROXY_URL;
  const [commonSetting, setCommonSetting] = useState<any>();
  const [bannerSetting, setBannerSetting] = useState<any>();
  const [contactInfos, setContactInfos] = useState([]);
  const [isDialog, setIsDialog] = useState(false);
  const [isFixed, setIsFixed] = useState(false);
  const [isNavbar, setIsNavbar] = useState(false);
  const location = useLocation();

  const handleHiddenNavbar = () => {
    setIsNavbar(false);
  };

  const getCommonSetting = useCallback(async () => {
    try {
      const { data }: any = await CommonSettingAPI.get({ populate: "*" });
      if (data) {
        setCommonSetting(data);
      }
    } catch (error) {
      console.error("getCommonSetting", error);
    }
  }, []);

  const getBannerSetting = useCallback(async () => {
    try {
      const { data }: any = await BannerSettingAPI.get({ populate: "*" });
      if (data) {
        setBannerSetting(data);
      }
    } catch (error) {
      console.error("getBannerSetting", error);
    }
  }, []);

  const getContactInfos = useCallback(async () => {
    try {
      const { data }: any = await ContactInfoAPI.getList({ populate: "*" });
      if (data) {
        setContactInfos(data);
      }
    } catch (error) {
      console.error("getContactInfos", error);
    }
  }, []);

  useEffect(() => {
    getCommonSetting().then();
    getBannerSetting().then();
    getContactInfos().then();

    function handleScroll() {
      if (window.scrollY > 300) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      className={`main-banner ${
        location.pathname === "/" ? " h-[100vh] " : " "
      }`}
    >
      <header
        className={`  ${
          isFixed
            ? "fixed top-0 left-0 w-full  bg-[#13c4a1] fixed-header "
            : "py-2"
        }`}
      >
        <div className="container">
          <nav className="navbar navbar-expand-md navbar-light">
            <img
              src={
                !isUsingCloudStorage
                  ? `${backendProxyURL}${commonSetting?.logo?.url}`
                  : `${commonSetting?.logo?.url}`
              }
              alt=""
            />
            <button
              onClick={() => setIsNavbar(!isNavbar)}
              className="navbar-toggler"
            >
              <svg
                className="w-[30px] h-[30px] "
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <path
                  fill="#ffff"
                  d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z"
                />
              </svg>
            </button>
            <div
              className={`navbar-collapse mean-menu ${
                isNavbar ? "block" : " mobile-hidden "
              }`}
            >
              <ul className="navbar-nav nav ml-auto">
                <li onClick={handleHiddenNavbar}>
                  <Link to={routePortals.HOME}>Home</Link>
                </li>
                <li onClick={handleHiddenNavbar}>
                  <Link to={routePortals.ABOUT}>About Us</Link>
                </li>
                <li onClick={handleHiddenNavbar}>
                  <Link to={routePortals.CONTACT}>Contact</Link>
                </li>
              </ul>
            </div>
            <div className="mr-auto others-option  mobile-hidden ">
              <ul onClick={() => setIsDialog(true)} className="navbar-nav ">
                <li data-toggle="modal">
                  <div className="side-menu">
                    <span className="bar-1"></span>
                    <span className="bar-2"></span>
                    <span className="bar-3"></span>
                  </div>
                </li>
              </ul>
              <div className="sidebar-modal ">
                <div
                  onClick={() => setIsDialog(false)}
                  className={`${
                    !isDialog ? " hidden" : "modal-backdrop fade show"
                  }`}
                ></div>
                <div
                  id="myModal2"
                  className={`modal right fade ${
                    isDialog ? "block" : "hidden "
                  } `}
                  aria-hidden="true"
                >
                  <div
                    role="document"
                    className={` modal-dialog ${
                      isDialog ? "right-0 e" : "right-[-320px] "
                    }`}
                  >
                    <div className="modal-content">
                      <div className="modal-header">
                        <button
                          onClick={() => setIsDialog(false)}
                          type="button"
                          data-dismiss="modal"
                          aria-label="Close"
                          className="close"
                        >
                          <span
                            aria-hidden="true"
                            className="flex items-center justify-center"
                          >
                            <svg
                              className="w-[32px] h-[30px]"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 384 512"
                            >
                              <path
                                fill="#ffffff"
                                d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
                              />
                            </svg>
                          </span>
                        </button>
                        <h2 id="myModalLabel2" className="modal-title">
                          <Link to="/">
                            <img
                              src={
                                !isUsingCloudStorage
                                  ? `${backendProxyURL}${commonSetting?.logo?.url}`
                                  : `${commonSetting?.logo?.url}`
                              }
                              alt="logo"
                            />
                          </Link>
                        </h2>
                      </div>
                      <div className="modal-body">
                        <div className="sidebar-modal-widget">
                          <h3 className="title">Contact Info</h3>
                          <ul className="contact-info">
                            {contactInfos.map((contactInfo: any) => {
                              if (contactInfo.type !== "social-network") {
                                return (
                                  <li key={contactInfo.id}>
                                    <i className="flex items-center justify-center">
                                      {/*{contactInfo?.svgImg}*/}
                                      <img
                                        src={
                                          !isUsingCloudStorage
                                            ? `${backendProxyURL}${contactInfo?.iconImg?.url}`
                                            : `${contactInfo?.iconImg?.url}`
                                        }
                                        className={"w-[25px] h-[27px]"}
                                        alt={""}
                                      />
                                    </i>
                                    {contactInfo?.title}{" "}
                                    <span>{contactInfo?.description}</span>
                                  </li>
                                );
                              }
                            })}
                          </ul>
                        </div>
                        <div className="sidebar-modal-widget">
                          <h3 className="title">Connect With Us</h3>
                          <ul className="social-list">
                            {contactInfos.map((contactInfo: any) => {
                              if (contactInfo.type === "social-network") {
                                return (
                                  <li key={contactInfo.id}>
                                    <Link to="#" target="_blank">
                                      <img
                                        src={
                                          !isUsingCloudStorage
                                            ? `${backendProxyURL}${contactInfo?.iconImg?.url}`
                                            : `${contactInfo?.iconImg?.url}`
                                        }
                                        className="w-[15px] h-[16px] "
                                        alt={""}
                                      />
                                    </Link>
                                  </li>
                                );
                              }
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </header>
      <div
        className={`p-banner ${
          location.pathname === "/about-us" ? "paddingnonev2" : ""
        } ${location.pathname === "/contact-us" ? "paddingnonev3" : ""}`}
      >
        <div
          className={`container ${
            location.pathname === "/about-us" ? "paddingnone" : ""
          } ${location.pathname === "/contact-us" ? "paddingnonev4" : ""}`}
        >
          {location.pathname === "/" && (
            <div className="flex gap-10 max-lg:flex-col-reverse items-center  justify-center">
              <img
                src={
                  !isUsingCloudStorage
                    ? `${backendProxyURL}${bannerSetting?.img?.url}`
                    : `${bannerSetting?.img?.url}`
                }
                alt=""
              />
              <div className="ml-10">
                <h1 className=" text-[50px] mb-[30px] text-[#fff] ">
                  {bannerSetting?.title}
                </h1>
                <button className="btn-banner">Join with us</button>
              </div>
            </div>
          )}
          {location.pathname === "/about-us" && (
            <div className="page-title-banner" style={{ position: "relative" }}>
              <h2 className="text_banner_aboutus" style={{}}>
                About Us
              </h2>
              <h2>
                <img style={{ width: "100vw" }} src={banneraboutus} alt="" />
              </h2>
            </div>
          )}
          {location.pathname === "/contact-us" && (
            <div className="page-title-banner">
              <Swiper
                autoplay={{ delay: 3000 }} // delay là khoảng thời gian giữa các slide, tính bằng mili giây (đây là ví dụ với 3000 mili giây)
                pagination={{ dynamicBullets: true }}
                cssMode={true}
                navigation={true}
                mousewheel={true}
                keyboard={true}
                modules={[Navigation, Pagination, Mousewheel, Keyboard]}
                className="mySwiper"
              >
                <SwiperSlide>
                  <div className="SwiperSlides">
                    <img src={banneraboutus} alt="" />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="SwiperSlides">
                    <img src={banneraboutus} alt="" />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="SwiperSlides">
                    <img src={banneraboutus} alt="" />
                  </div>
                </SwiperSlide>
              </Swiper>
              <h2 className="textcontactus">Contact Us</h2>
            </div>
          )}
        </div>
      </div>
      <div className=" shape-1"></div>
      <div className=" shape-2">
        <img src={shape2} alt="" />
      </div>
      <div className=" shape-3">
        <img src={shape3} alt="" />
      </div>
    </div>
  );
};
export default Header;
