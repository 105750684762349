import React, { Suspense } from 'react';
import Header from '../organisms/components/Header';
import Footer from '../organisms/components/Footer';


export type PropsLayout = {
    children: React.ReactNode;
};
const LayoutPage = ({ children }: any) => {
    return (
        <div>
            <Header />
            <div className=' '>
                <Suspense fallback={<div></div>} >
                    {children}
                </Suspense>
            </div>
            <Footer />
        </div>
    )
}
export default LayoutPage