import axios, { AxiosInstance, AxiosResponse } from 'axios';

interface EnvVariables {
    REACT_APP_BACKEND_PROXY_URL: string;
    REACT_APP_API_NAME_SPACE: string;
    REACT_APP_API_TOKEN: string;
}

function getEnvVariables(): EnvVariables {
    const { REACT_APP_BACKEND_PROXY_URL, REACT_APP_API_NAME_SPACE, REACT_APP_API_TOKEN } = process.env;
    if (!REACT_APP_BACKEND_PROXY_URL) {
        throw new Error('REACT_APP_BACKEND_PROXY_URL not found in environment variables');
    }
    if (!REACT_APP_API_NAME_SPACE) {
        throw new Error('REACT_APP_API_NAME_SPACE not found in environment variables');
    }
    if (!REACT_APP_API_TOKEN) {
        throw new Error('REACT_APP_API_TOKEN not found in environment variables');
    }
    return { REACT_APP_BACKEND_PROXY_URL, REACT_APP_API_NAME_SPACE, REACT_APP_API_TOKEN };
}

export class HttpService {
    private axiosInstance: AxiosInstance;
    private readonly token: string;

    constructor() {
        const { REACT_APP_BACKEND_PROXY_URL, REACT_APP_API_NAME_SPACE, REACT_APP_API_TOKEN } = getEnvVariables();
        this.token = REACT_APP_API_TOKEN;

        this.axiosInstance = axios.create({
            baseURL: `${REACT_APP_BACKEND_PROXY_URL}${REACT_APP_API_NAME_SPACE}`, // Change this to your API base URL
            timeout: 5000, // Adjust timeout as needed
            headers: {
                'Content-Type': 'application/json',
            },
        });

        this.axiosInstance.interceptors.request.use((config) => {
            config.headers.Authorization = `Bearer ${this.token}`;
            return config;
        });
    }

    async get<T>(url: string, params?: object): Promise<T> {
        try {
            const response: AxiosResponse<T> = await this.axiosInstance.get<T>(url, params);
            return response.data;
        } catch (error: any) { // Explicitly specify error type
            throw new Error(`GET request failed: ${(error as Error).message}`);
        }
    }

    async post<T>(url: string, data: any): Promise<T> {
        try {
            const response: AxiosResponse<T> = await this.axiosInstance.post<T>(url, data);
            return response.data;
        } catch (error: any) {
            throw new Error(`POST request failed: ${(error as Error).message}`);
        }
    }

    async put<T>(url: string, data: any): Promise<T> {
        try {
            const response: AxiosResponse<T> = await this.axiosInstance.put<T>(url, data);
            return response.data;
        } catch (error: any) {
            throw new Error(`PUT request failed: ${(error as Error).message}`);
        }
    }

    async delete<T>(url: string): Promise<T> {
        try {
            const response: AxiosResponse<T> = await this.axiosInstance.delete<T>(url);
            return response.data;
        } catch (error: any) {
            throw new Error(`DELETE request failed: ${(error as Error).message}`);
        }
    }
}
